import logo from './logo.svg';
import './App.css';
import AddForm from './AddForm';
import Login from './Login';
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from './Home';
import Snapling from './Snapling';
import Ligth from './EventsAndProductions';
import Jip from './Jip';
import Bice from './Bice';
import NavBar from './NavBar';
import Footer from './Footer';
import { Fragment } from 'react';
import Connect2 from './Connect2';
import GreenAndSurprising from './GreenAndSurprising';
import EventsAndProductions from './EventsAndProductions';
import MealsInTheField from './MealsInTheField';
import RopePark from './RopePark';
import TitlebarImageList from './TitlebarImageList';
import SlideGallery from './SlideGallery';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { width } from '@mui/system';
import CollapsedSidepanel from './CollapsedSidepanel';
import Contact from './Contact';
function App() {
  let nav = useNavigate();
  const goToHome = () => {
    nav("/home");
  }
  return (<Fragment>
    <img src="../../up.png" alt="logo" onClick={goToHome} style={{ cursor:"pointer",maxWidth: "100%", height: "auto" }} />
    {/* <a href="tel:0548423630"><Button variant="contained" style={{backgroundColor:'red' }}>התקשרו עכשיו 0548423630 </Button></a> */}
{/* <CollapsedSidepanel/> */}
    <NavBar />
    <div className='App-header'  >
      <Routes>
        <Route path='home' element={<Home/>} />
        <Route path='' element={<Home/>} />
        <Route path='snapling' element={<Snapling/>} />
        <Route path='productionAndLogistics' element={<Ligth/>} />
        <Route path='jip' element={<Jip />} />
        <Route path='RopePark' element={<RopePark/>} />
        <Route path='bice' element={<Bice/>} />
        <Route path='contact' element={<Connect2/>} />
        <Route path='GreenAndSurprising' element={<GreenAndSurprising/>} />
        <Route path='EventsAndProductions' element={<EventsAndProductions/>} />
        <Route path='MealsInTheField' element={<MealsInTheField/>} />
        <Route path='contact' element={<Contact/>} />


      </Routes>
    </div>
    <Tooltip title="דברו איתנו גם בווצאפ" followCursor>
      <IconButton>
        <a target="_blank" href="https://api.whatsapp.com/send?phone=972548423630">  <WhatsAppIcon style={{ position: "fixed", bottom: "15", right: "15", backgroundColor: "white", color: "green", width: "40px", height: "40px" }} /></a>
      </IconButton>
    </Tooltip>
{/* https://web.whatsapp.com/send?phone=972548423630&text=%D7%A4%D7%A0%D7%99%D7%94%20%D7%9E%D7%94%D7%90%D7%AA%D7%A8%20: */}

    <Footer />

  </Fragment>);
}

export default App;
