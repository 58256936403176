import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./App.css"
import { Link, useNavigate } from 'react-router-dom';
import "./NavBar.css";
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import SlideGallery from './SlideGallery';
import { TimeToLeave } from '@mui/icons-material';

const Home = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  let nav = useNavigate();
  const goToBice = () => {
    nav("/bice");
  }
  const goToJip = () => {
    nav("/jip");
  }
  const goToRopePark = () => {
    nav("/ropePark");
  }
  const goToMeal = () => {
    nav("/MealsInTheField");
  }
  const goToLigth = () => {
    nav("/EventsAndProductions");
  }
  const goToGreen = () => {
    nav("/GreenAndSurprising");
  }
  const goToHome = () => {
    nav("/home");
  }
  const goToSnapling = () => {
    nav("/snapling");
  }
  const goToConnect = () => {
    nav("/connect2");
  }
const images=[
  "../../pic/kum/k (1).jpg",
  "../../pic/kum/k (2).jpg",
  "../../pic/kum/k (3).jpg",
  "../../pic/kum/k (4).jpg",
  "../../pic/kum/k (5).jpg",
  "../../pic/sadna/s (2).jpg",
  "../../pic/sadna/s (3).jpg",
  "../../pic/sadna/s (4).jpg",
  "../../pic/sadna/s (5).jpg",
  "../../pic/sadna/s (8).jpg",
  "../../pic/sweet/sw (1).jpg",
  "../../pic/sweet/sw (2).jpg",
  "../../pic/sweet/sw (3).jpg",
  "../../pic/sweet/sw (4).jpg",
  "../../pic/sweet/sw (5).jpg",
  "../../pic/sweet/sw (6).jpg",
  "../../pic/sweet/sw (7).jpg",
  "../../pic/sweet/sw (8).jpg",
  "../../pic/sweet/sw (9).jpg",
  "../../pic/sweet/sw (10).jpg",
  "../../pic/sweet/sw (11).jpg",
  "../../pic/sweet/sw (12).jpg",
  "../../pic/sweet/sw (13).jpg",
  "../../pic/sweet/sw (14).jpg",
  "../../pic/sweet/sw (15).jpg",
  "../../pic/sweet/sw (16).jpg",
  "../../pic/sweet/sw (17).jpg",
  "../../pic/sweet/sw (18).jpg",
  "../../pic/sweet/sw (19).jpg",
  "../../pic/sweet/sw (20).jpg",
  "../../pic/trip/t (1).jpg",
  "../../pic/trip/t (3).jpg",
  "../../pic/trip/t (4).jpg",
  "../../pic/trip/t (5).jpg",
  "../../pic/trip/t (6).jpg",
  "../../pic/trip/t (7).jpg",
  "../../pic/trip/t (8).jpg",

]

const myStyle={
  backgroundImage: 
"url('../../pic/nof/n (3).jpg')",
  height:'100vh',
  marginTop:'-70px',
  fontSize:'50px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};
  return (<>
  <br/>
    <div className="all" style={{ display: "flex", justifyContent: "space-between" , position: "-webkit-sticky",position:"sticky",top:" 20" }}>
      <div className="flip-box" onClick={goToJip} >
        <div className="flip-box-inner">
          <div className="flip-box-front">
            <img src="../../pic/r/jip.jpg" alt="ג'יפים" style={{cursor:"pointer", width: "200px", height: "200px", borderRadius: "50%" }} />
          </div>
        </div>
        <div className="flip-box-back">
          <h2>טיולי ג'יפים</h2>
        </div>
      </div>
      <div className="flip-box" onClick={goToBice} >
        <div className="flip-box-inner">
          <div className="flip-box-front">
            <img src="../../pic/r/bice1.jpg" alt="אופנים" style={{ cursor:"pointer",width: "200px", height: "200px", borderRadius: "50%" }} />
          </div>
        </div>
        <div className="flip-box-back">
          <h2>טיולי אופנים</h2>
        </div>
      </div>
      <div className="flip-box" onClick={goToMeal} >
        <div className="flip-box-inner">
          <div className="flip-box-front">
            <img src="../../pic/r/meal.jpg" alt="אוכל" style={{ cursor:"pointer",width: "200px", height: "200px", borderRadius: "50%" }} />
          </div>
        </div>
        <div className="flip-box-back">
          <h2>ארוחות בשטח </h2>
        </div>
      </div>
      <div className="flip-box" onClick={goToLigth} >
        <div className="flip-box-inner">
          <div className="flip-box-front">
            <img src="../../pic/r/ligth.jpg" alt="הפקות ולוגיסטיקה" style={{cursor:"pointer", width: "200px", height: "200px", borderRadius: "50%" }} />
          </div>
        </div>
        <div className="flip-box-back">
          <h2> הפקות ולוגיסטיקה</h2>
        </div>
      </div>
      <div className="flip-box" onClick={goToSnapling} >
        <div className="flip-box-inner">
          <div className="flip-box-front">
            <img src="../../pic/r/snap.jpg" alt="סנפלינג" style={{ cursor:"pointer",width: "200px", height: "200px", borderRadius: "50%" }} />
          </div>
        </div>
        <div className="flip-box-back">
          <h2> סנפלינג</h2>
        </div>
      </div>
      <div className="flip-box" onClick={goToGreen} >
        <div className="flip-box-inner">
          <div className="flip-box-front">
            <img src="../../pic/r/me.jpg" alt="מחיצה מצמחיה" style={{cursor:"pointer", width: "200px", height: "200px", borderRadius: "50%" }} />
          </div>
        </div>
        <div className="flip-box-back">
          <h2> מחיצה מצמחיה</h2>
        </div>
      </div>
      <div className="flip-box" onClick={goToRopePark} >
        <div className="flip-box-inner">
          <div className="flip-box-front">
            <img src="../../pic/r/park.jpg" alt="פארק חבלים" style={{ cursor:"pointer",width: "200px", height: "200px", borderRadius: "50%" }} />
          </div>
        </div>
        <div className="flip-box-back">
          <h2>פארק חבלים אתגרי נייד </h2>
        </div>
      </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
<div>
    <Box sx={{ width: '80%', maxWidth: 600 }} >

      <Typography className='di' variant="h3" gutterBottom >
                                         אודותינו...
      </Typography>
      <Typography className='di' variant="h6" gutterBottom >
        <p> החל משנת 2008 חברת הר הרוח מפיקה ימי פעילות,
          טיולים ואירועים מדהימים בשטח.</p>
        <p> קבוצות גדולות מהארץ ומהעולם, משפחות שרוצות להיפגש יחד לזמן איכות חוויתי בטבע,
          ועדי עובדים וצוותים, </p>
        <p>תבינו- אין כמו גיבוש בטבע להקפיץ את התפוקה מהעובדים!</p>
        <p>תשקיעו בהם זה בטוח ישתלם…</p>
        <p>אנו מאמינים כי היציאה לטבע ממלאת בהשראה ונותנת בוסט לאנרגיות
          כאלה מדהימות שיקדמו אתכם הכי גבוה שאפשר!</p>
        <p>הכיף שלנו הוא להנגיש גם לכם את העוצמה שיש לטבע להציע.</p>
        <p>אז קדימה סוגרים עכשיו יום חוויה עוצמתי בשטח.</p>
        {/* <a href="tel:0548423630"><Button variant="contained"  style={{ backgroundColor: 'rgb(138, 203, 120)', display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו 0548423630 </Button></a> */}
  <a href="tel:0548423630"><Button variant="contained"  style={{ backgroundColor: "red", display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו 0548423630 </Button></a>
<br></br>
<br></br>
<br></br>
      </Typography>
    </Box>
    </div>
    <SlideGallery images={images} />

  </>);
};
export default Home;
