
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./App.css"
import SlideGallery from './SlideGallery';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import "./photo.css";

const GreenAndSurprising = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 450, left: 0, behavior: 'smooth'});
  }, []);
  let nav = useNavigate();
  const goToBice = () => {
    nav("/bice");
  }
  const goToJip = () => {
    nav("/jip");
  }
  const goToRopePark = () => {
    nav("/ropePark");
  }
  const goToMeal = () => {
    nav("/MealsInTheField");
  }
  const goToLigth = () => {
    nav("/EventsAndProductions");
  }
  const goToGreen = () => {
    nav("/GreenAndSurprising");
  }
  const goToHome = () => {
    nav("/home");
  }
  const goToSnapling = () => {
    nav("/snapling");
  }
  const goToConnect = () => {
    nav("/connect2");
  }

  const images = [
    '../../pic/me/me (1).jpg',
    '../../pic/me/me (2).jpg',
    '../../pic/me/me (3).jpg',
    '../../pic/me/me (4).jpg',
    '../../pic/me/me (5).jpg',
    '../../pic/me/me (6).jpg',
    '../../pic/me/me (7).jpg'
  ];

  return (<>
   <div className='row'>

<div className='main'>
  <br></br>
  <h1 style={{ alignContent: "center", textAlign: 'center' }}>
     ירוק ומפתיע
  </h1>
  <img src="../../pic/me/me (1).jpg" alt="מחיצה" style={{ width: "75%", height: "45%", borderRadius: "2%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
  <div>
  <p>חשבתם פעם מה עושה אירוע ליותר חי, יותר טבעי ומיוחד? </p>
        <p> סוד האולם הפורח;)</p>
        <p>לא צריך לצאת לאירוע בטבע אפשר להביא את הטבע לאירוע. </p>
        <p>תלבישו את האולם בירוק ממש כמו ב'גן אירועים'</p>
        <p> באירוע הבא מזמינים מגוון עצים, צמחים, עציצים פורחים, דקלים, פיקוסים, מחיצות ירוקות. </p>
        <p>הצמחייה הירוקה והרעננה בשילוב תאורה צבעונית יקפיצו לכם את הלוק של האירוע
          ויהפכו אותו להרבה יותר מרשים, מיוחד, אחר ומגוון.</p>
        <p>האורחים שיגיעו יתפעלו (מתחילת האירוע ועד סופו, מניסיון…)
          ולא יגמרו לשבח ולפרגן על ההפקה שהרמתם! </p>
        <p> הצמחיה ניתנת לשינוע …</p>
    <a href="tel:0548423630"><Button variant="contained" style={{ backgroundColor: 'red', display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו להזמין מחיצה מצמחיה 0548423630 </Button></a>

  </div>
</div>
<div className='side'>
  <h3>אז במה עוד אנחנו מתמחים?</h3>
  <div onClick={goToJip}>
  <div onClick={goToRopePark}>
    <h4> פארק חבלים אתגרי נייד</h4>
    <img className='demo' src="../../pic/park/p (14).jpg" alt="פארק חבלים אתגרי נייד" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
    <h4>טיולי ג'יפים</h4>
    <img className='demo' src="../../pic/jip/j (8).jpg" alt="ג'יפים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
  <div onClick={goToMeal}>
    <h4> ארוחות בשטח </h4>
    <img className='demo' src="../../pic/meal/m (26).jpg" alt="אוכל" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
 
  <div onClick={goToBice}>
    <h4>  טיולי אופנים</h4>
    <img className='demo' src="../../pic/bice/b (14).jpg" alt="   טיולי אופנים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
</div>
</div>
<br></br>
<SlideGallery images={images} />
       
      
  </>);
};

export default GreenAndSurprising;