import "./Footer.css";
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import FaxIcon from '@mui/icons-material/Fax';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { padding } from "@mui/system";

const Footer = () => {
  let nav = useNavigate();
  const goToHome = () => {
    nav("/home");
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: "center",
        flexDirection: 'column',
        minHeight: '10vh',
      }}
    >
      <CssBaseline />

      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: "#333"
        }}
      >
        <Container maxWidth="1500px;" className="write" style={{ direction: "rtl" }}>
          <div className="all">

            <div className="each" style={{padding:"40px"}}>
              <div style={{ color: "white" }}><HomeIcon />  יצחק 23 ,קרית יערים </div>
              <a href="tel:025335337" style={{ color: "white" }} > <PhoneEnabledIcon /> 02-5335337 </a> <br />
              <a href="tel:0548423630" style={{ color: "white" }}><PhoneAndroidIcon /> 054-8423630  </a>
              <div style={{ color: "white" }}> <LocalPrintshopIcon /> 02-5700801  </div>
              <Tooltip title="לחץ לשליחת מייל  " followCursor>
                <div onClick={() => window.location = 'mailto:har.haruach@gmail.com'} style={{ color: "white" }}><AlternateEmailIcon />  har.haruach@gmail.com </div>
              </Tooltip>
            </div>
            <div className="each">
              <Tooltip title="לחץ למעבר לגוגל מפות" followCursor>
                <IconButton>
                  <a target="_blank" href="https://www.google.com/maps/place/%D7%94%D7%A8%D7%91+%D7%99%D7%A6%D7%97%D7%A7+23,+%D7%A7%D7%A8%D7%99%D7%AA+%D7%99%D7%A2%D7%A8%D7%99%D7%9D%E2%80%AD/@31.804106,35.1060339,17z/data=!3m1!4b1!4m6!3m5!1s0x1502d0dde6480f0d:0x47913d2c025617d7!8m2!3d31.804106!4d35.1060339!16s%2Fg%2F11h88nvl19?hl=iw"> <img src="../../map.png" onclick={() => { }} style={{ width: "100%", height: "200px" }} /></a>
                </IconButton>
              </Tooltip>
            </div>

            
            <div className="each">
              {/* <div className="logo" onClick={goToHome}> <img src="../../logo2.jpg" style={{ cursor:"pointer",width: "55%", height: "100px" }} /></div> */}
             <Link to="home" style={{ color: "white" }}> <b>דף הבית</b></Link> <br/>
              <Link to="productionAndLogistics" style={{ color: "white" }}><b>אירועים והפקות </b></Link><br/>
              <Link to="mealsInTheField" style={{ color: "white" }}> <b>ארוחות בשטח </b></Link><br/>
              <Link to="bice" style={{ color: "white" }}> <b>טיולי אופנים </b></Link><br/>

              <Link to="ropePark" style={{ color: "white" }}> <b>פארק חבלים   </b></Link><br/>
              <Link to="greenAndSurprising" style={{ color: "white" }}> <b> ירוק ומפתיע</b></Link><br/>
              <Link to="snapling" style={{ color: "white" }}> <b>סנפלינג      </b></Link><br/>
              <Link to="jip" style={{ color: "white" }}> <b>טיולי ג'יפים </b></Link><br/>
                            <Link to="contact" style={{ color: "white" }} ><b>צור קשר </b></Link> <br/>

            </div>
          </div>
        </Container>
      </Box>
    </Box>
  );
}


export default Footer;