import { useState } from "react";
import "./Connect.css";
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import FaxIcon from '@mui/icons-material/Fax';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
//import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect } from "react";
import Contact from "./Contact";
import Contact1 from "./Contact1";

const Connect2 = () => {
   

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 450, left: 0, behavior: 'smooth'});
      }, []);
    let [ask, setAsk] = useState({
        name: undefined,
        email: undefined,
        phone: undefined,
        question: undefined,
    });


    let handleChange = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        if (e.target.type == 'number')
            inputValue = +inputValue;
        let a = { ...ask };
        a[inputName] = inputValue;
        setAsk(a);
    }

    let handleSubmit = (e) => {
        e.preventDefault();
        alert(`תודה ${ask.name} , הפנייה התקבלה בהצלחה!`)
    }
   
      
    return (<>
     <Contact/>
     {/* <Contact1/> */}
    {/* <div className="row">
    <div className="right">
        <h1>צור קשר</h1>
        <div><HomeIcon/> קרית יערים רחוב יצחק 23  </div>
        <a href="tel:025335337" style={{color:"black"}} > <PhoneEnabledIcon/>טלפון 02-5335337 </a> <br/>
        <a href="tel:0548423630" style={{color:"black"}}><PhoneAndroidIcon/>נייד 054-8423630  </a>
        <div> <LocalPrintshopIcon/>פקס 02-5700801  </div>
        <Tooltip title="לחץ לשליחת מייל  " followCursor>
        <div onClick={() => window.location = 'mailto:har.haruach@gmail.com'} style={{color:"black"}}><AlternateEmailIcon/> מייל har.haruach@gmail.com </div>
        </Tooltip>
        </div>

        <div className="left">
        <Tooltip title="לחץ למעבר לגוגל מפות" followCursor>
  <IconButton>
        <a target="_blank" href="https://www.google.com/maps/place/%D7%94%D7%A8%D7%91+%D7%99%D7%A6%D7%97%D7%A7+23,+%D7%A7%D7%A8%D7%99%D7%AA+%D7%99%D7%A2%D7%A8%D7%99%D7%9D%E2%80%AD/@31.804106,35.1060339,17z/data=!3m1!4b1!4m6!3m5!1s0x1502d0dde6480f0d:0x47913d2c025617d7!8m2!3d31.804106!4d35.1060339!16s%2Fg%2F11h88nvl19?hl=iw"> <img src="../../map.png"  onclick={()=>{}} style={{width:"100%", height:"300px"}}/></a>
        </IconButton>
        </Tooltip>
       </div>
       </div> */}
      
        {/* <Map
          google={this.props.google}
          zoom={8}
          style={mapStyles}
          initialCenter={{ lat: 47.444, lng: -122.176}}
        /> */}
        {/* <form className="formStyle" onSubmit={handleSubmit}>
            <div>
                <label>   שם    </label>
                <input type={"text"} name="name" onChange={handleChange} />
            </div>
            <br />
            <div>
                <label>טלפון  </label>
                <input type={"tel"} name="phone" onChange={handleChange} />
            </div>
            <br />

            <div>
                <label>אמייל </label>
                <input type={"email"} name="email" onChange={handleChange} />
            </div>
            <br />

            <div>
                <textarea placeholder="רשמו את פנייתכם כאן..." onChange={handleChange} />
            </div>

            <br />

            < input type={"submit"} value="שלח" />
        </form> */}
    </>);
}

export default Connect2; 

