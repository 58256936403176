import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function StandardImageList() {
  return (
    <ImageList sx={{ width: 1500, height: 2000}} cols={3} rowHeight={300}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=300&h=300&fit=crop&auto=format`}
            srcSet={`${item.img}?w=300&h=300&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: '../../pic/bice/b (1).jpg',
    title: 'אופנים',
  },
  {
    img: '../../pic/bice/b (2).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (3).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (4).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (5).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (6).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (7).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (8).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (9).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (10).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (11).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (12).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (13).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (14).jpg',
    title: 'אופנים',
  },{
    img: '../../pic/bice/b (15).jpg',
    title: 'אופנים',
  },
];
