

import { red } from "@mui/material/colors";
import { display } from "@mui/system";
import { NavLink, unstable_HistoryRouter, useNavigate } from "react-router-dom";
import "./NavBar.css";


const NavBar = () => {
  let ActiveNavLink = 'activeNavLink'
 
  return (<>

<div id="navbar" className="navbar">
  <NavLink to="contact" activestyle={{  backgroundColor:red }} ><i className="fas fa-contact"></i><b>צור קשר </b></NavLink>
  <NavLink to="ropePark"> <b>פארק חבלים   </b></NavLink>
  <NavLink to="greenAndSurprising"> <b> ירוק ומפתיע</b></NavLink>
  <NavLink to="snapling"> <b>סנפלינג      </b></NavLink>
  <NavLink to="jip"> <b>טיולי ג'יפים </b></NavLink>
   <NavLink to="bice"> <b>טיולי אופנים </b></NavLink> 
  <NavLink to="mealsInTheField"> <b>ארוחות בשטח </b></NavLink> 
  <NavLink to="productionAndLogistics"><b>אירועים והפקות </b></NavLink> 
  <NavLink to="home"> <b>דף הבית</b></NavLink>   
</div>
   


  </>);
}

export default NavBar;