import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TitlebarImageList from './TitlebarImageList';
import "./App.css"
import SlideGallery from './SlideGallery';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import Demo from './Demo';
import "./photo.css";


const Bice = () => {
 
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 450, left: 0, behavior: 'smooth'});
  }, []);
  
  const images = [
    "../../pic/bice/b (1).jpg",
    "../../pic/bice/b (2).jpg",
    '../../pic/bice/b (3).jpg',
    '../../pic/bice/b (4).jpg',
    '../../pic/bice/b (5).jpg',
    '../../pic/bice/b (6).jpg',
    '../../pic/bice/b (7).jpg',
    '../../pic/bice/b (8).jpg',
    '../../pic/bice/b (9).jpg',
    '../../pic/bice/b (10).jpg',
    '../../pic/bice/b (11).jpg',
    '../../pic/bice/b (12).jpg',
    '../../pic/bice/b (13).jpg',
    '../../pic/bice/b (14).jpg',
    '../../pic/bice/b (15).jpg',
    '../../pic/bice/b (16).jpg',
    '../../pic/bice/b (17).jpg',
    '../../pic/bice/b (18).jpg',
    '../../pic/bice/b (19).jpg',
    '../../pic/bice/b (20).jpg',
    '../../pic/bice/b (21).jpg',
    '../../pic/bice/b (22).jpg',
    '../../pic/bice/b (23).jpg',
    '../../pic/bice/b (24).jpg',
    '../../pic/bice/b (25).jpg',
    '../../pic/bice/b (26).jpg',
    '../../pic/bice/b (27).jpg',
    '../../pic/bice/b (28).jpg',
    '../../pic/bice/b (29).jpg',
    '../../pic/bice/b (30).jpg',
    '../../pic/bice/b (31).jpg',
    '../../pic/bice/b (32).jpg',
    '../../pic/bice/b (33).jpg',
    '../../pic/bice/b (34).jpg',
    '../../pic/bice/b (35).jpg',
    '../../pic/bice/b (36).jpg',
    '../../pic/bice/b (37).jpg',
    '../../pic/bice/b (38).jpg',
    '../../pic/bice/b (39).jpg',
    '../../pic/bice/b (40).jpg',
    '../../pic/bice/b (41).jpg',
    '../../pic/bice/b (42).jpg',
    '../../pic/bice/b (43).jpg',
    '../../pic/bice/b (44).jpg',
    '../../pic/bice/b (45).jpg',
    '../../pic/bice/b (46).jpg',
    '../../pic/bice/b (47).jpg',
    '../../pic/bice/b (48).jpg',
    '../../pic/bice/b (49).jpg',
    '../../pic/bice/b (50).jpg',
    '../../pic/bice/b (51).jpg',
    '../../pic/bice/b (52).jpg',
    '../../pic/bice/b (53).jpg',
    '../../pic/bice/b (54).jpg',
    '../../pic/bice/b (55).jpg',
    '../../pic/bice/b (56).jpg',
    '../../pic/bice/b (57).jpg',
    '../../pic/bice/b (58).jpg'

  ];
  let nav = useNavigate();
  const goToBice = () => {
    nav("/bice");
  }
  const goToJip = () => {
    nav("/jip");
  }
  const goToRopePark = () => {
    nav("/ropePark");
  }
  const goToMeal = () => {
    nav("/MealsInTheField");
  }
  const goToLigth = () => {
    nav("/EventsAndProductions");
  }
  const goToGreen = () => {
    nav("/GreenAndSurprising");
  }
  const goToHome = () => {
    nav("/home");
  }
  const goToSnapling = () => {
    nav("/snapling");
  }
  const goToConnect = () => {
    nav("/connect2");
  }

  return (<>
    <div className='row'>

      <div className='main'>
        <br></br>

        <h1 style={{ alignContent: "center", textAlign: 'center'}}>
         {/* fontFamily:'Guttman Yad' ,fontSize:"30px"}}> */}
          טיולי אופנים
        </h1>
        <img src="../../pic/bice/b (6).jpg" alt="אופנים" style={{ width: "75%", height: "45%",borderRadius:"2%", display: "block", marginLeft: "auto", marginRight: "auto" }} />

        <div>
          <p> חברת הר הרוח מפעילה ומדריכה טיולי אופניים חווייתיים לקבוצות בחיק הטבע בכל רחבי הארץ. </p>
          <p>ברשותנו צי אופני שטח,  מתוחזק ומתקדם מחברות מובילות שיובילו אתכם לטיולים הכי שווים, </p>
          <p>טיולים אתגריים ומלאי אדרנלין שיספקו למשתתפים חוויה מהנה ומשמעותית ברכיבה על אופניים. </p>
          <p>הסוד להצלחת טיול רכיבה נעוץ בהתאמת מסלול שמתאים לקבוצה.</p>
          <p> עם השנים התגלגלנו על האופניים מהדרום המדברי ועד לצפון הרחוק </p>
          <p>וכך צברנו ידע וניסיון רב בבחירת מסלולים המתאימים ביותר לקבוצות גדולות, תוך שימת דגש על רמות רכיבה שונות.</p>
          <p>כך נוכל להמליץ בחום על טיול המתאים בדיוק לקבוצה שלכם. </p>
          <p>בקיצור, לכם נשאר רק לבחור : בהר, במדבר או בשפלה? </p>
          <p>(מה הצבע שאתם רוצים לפגוש בדרך - ירוק של הר חום של מדבר או כחול של ים?)</p>
          <p>לרשותנו מדריכים מוסמכים ומיקצועיים וביטוחים מלאים.  </p>
          <a href="tel:0548423630"><Button variant="contained" style={{ backgroundColor: 'red', display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו להזמין טיול אופניים 0548423630 </Button></a>

        </div>
      </div>
      <div className='side'>
        <h3>אז במה עוד אנחנו מתמחים?</h3>
        <div onClick={goToJip}>
          <h4>טיולי ג'יפים</h4>
          <img className='demo' src="../../pic/jip/j (8).jpg" alt="ג'יפים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToMeal}>
          <h4> ארוחות בשטח </h4>
          <img className='demo' src="../../pic/meal/m (14).jpg" alt="אוכל" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToRopePark}>
          <h4> פארק חבלים אתגרי נייד</h4>
          <img className='demo' src="../../pic/park/p (14).jpg" alt="פארק חבלים אתגרי נייד" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToSnapling}>
          <h4> סנפלינג</h4>
          <img className='demo' src="../../pic/snap/sn (4).jpg" alt="  סנפלינג " style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
      </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    {/* <Demo images={images}/> */}
    <SlideGallery images={images} />
    
  </>);
};

export default Bice;