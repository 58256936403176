
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./App.css"
import SlideGallery from './SlideGallery';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import "./photo.css";

const Snapling = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 450, left: 0, behavior: 'smooth'});
  }, []);
  let nav = useNavigate();
  const goToBice = () => {
    nav("/bice");
  }
  const goToJip = () => {
    nav("/jip");
  }
  const goToRopePark = () => {
    nav("/ropePark");
  }
  const goToMeal = () => {
    nav("/MealsInTheField");
  }
  const goToLigth = () => {
    nav("/EventsAndProductions");
  }
  const goToGreen = () => {
    nav("/GreenAndSurprising");
  }
  const goToHome = () => {
    nav("/home");
  }
  const goToSnapling = () => {
    nav("/snapling");
  }
  const goToConnect = () => {
    nav("/connect2");
  }

  const images = [
    '../../pic/snap/sn (1).jpg',
    '../../pic/snap/sn (2).jpg',
    '../../pic/snap/sn (3).jpg',
    '../../pic/snap/sn (4).jpg',
    '../../pic/snap/sn (5).jpg',
    '../../pic/snap/sn (6).jpg',
    '../../pic/snap/sn (7).jpg'

  ];

  return (<>
   <div className='row'>

<div className='main'>
  <br></br>
  <h1 style={{ alignContent: "center", textAlign: 'center' }}>
     סנפלינג
  </h1>
  <img src="../../pic/snap/sn (7).jpg" alt="סנפלינג" style={{ width: "75%", height: "45%", borderRadius: "2%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
  <div>
  <p>קושרים רתמה, שמים קסדה והנה הרגע הזה שאתם עוזבים את הרגליים מהקרקע היציבה. </p>
        <p ><b>אמאלה!!!</b></p>
        <p>איזה ריגוש, איזה אקסטרים!</p>
        <p>סנפלינג - פעילות המשלבת טבע, ספורט ואתגר, </p>
        <p>גלישת צוקים מדהימה שתגרום ללב שלכם להחסיר פעימה.</p>
        <p>יש לנו צוות מקצועי ומיומן והכי חשוב- נחמד, שנותן יחס אישי ומשרה אווירה נעימה ורגועה</p>
        <p>ובינינו, איזה כיף לשים שוב רגל על הקרקע הבטוחה ולהרגיש שהתגברנו על הפחד…</p>
        <p>החברה מספקת את שרותיה גם למגזר הדתי. ישנה אפשרות למדריכות מוסמכות.</p>
    <a href="tel:0548423630"><Button variant="contained" style={{ backgroundColor: 'red', display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו להזמין סנפלינג  0548423630 </Button></a>

  </div>
</div>
<div className='side'>
  <h3>אז במה עוד אנחנו מתמחים?</h3>
  <div onClick={goToJip}>
    <h4>טיולי ג'יפים</h4>
    <img className='demo' src="../../pic/jip/j (8).jpg" alt="ג'יפים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
  <div onClick={goToMeal}>
    <h4> ארוחות בשטח </h4>
    <img className='demo' src="../../pic/meal/m (14).jpg" alt="אוכל" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
  <div onClick={goToRopePark}>
    <h4> פארק חבלים אתגרי נייד</h4>
    <img className='demo' src="../../pic/park/p (14).jpg" alt="פארק חבלים אתגרי נייד" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
  <div onClick={goToLigth}>
    <h4> הפקות ואירועים</h4>
    <img className='demo' src="../../pic/ligth/l (4).jpg" alt="  הפקות ואירועים " style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
</div>
</div>
<br></br>
<SlideGallery images={images} />
        
     
  </>);
};

export default Snapling;