import React from "react";
import axios from "axios";

class Contact1 extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          message: ''
        }
}
resetForm(){
    this.setState({name: '', email: '', message: ''})
  }

onNameChange(event) {
    this.setState({name: event.target.value})
  }
  onEmailChange(event) {
    this.setState({email: event.target.value})
  }
  onMessageChange(event) {
    this.setState({message: event.target.value})
  }
  handleSubmit(e) {
    e.preventDefault();
    axios({
      method: "POST",
      url:"http://localhost:3002/send",
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert("Message Sent.");
        this.resetForm()
      } else if(response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  
  }
    render(){
        return(<>
            <div className="App">
            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
              <div className="form-group">
                <label for="name">שם</label>
                <input type="text" id="name" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
              </div>
              <div className="form-group">
                <label for="exampleInputEmail1"> כתובת אמייל</label>
                <input type="email" id="exampleInputEmail1" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
              </div>
              <div className="form-group">
                <label for="message">עוד כמה מילים...</label>
                <textarea id="message" className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
              </div>
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
       </> );          
    };
} ;

export default Contact1;