import { useState } from "react";
import './AddForm.css';


function AddForm() {
    let [person, setPerson] = useState({
        name: "bittya",
        age: undefined,
        password: undefined,
        email: undefined,
        isStudent: true,
        ezrachut: undefined,

    })
    let [errors, setErrors] = useState({});
    const change = (e) => {
        let inputName = e.target.name;
        let inputValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if (e.target.type == 'number')
            inputValue = +inputValue;
        let p = { ...person };
        p[inputName] = inputValue;
        setPerson(p);
    }
    const save = (e) => {
        e.preventDefault();
        if (!validation()) return;
        alert(`name:${person.name},age:${person.age},password:${person.password},email:${person.email},ezrachut:${person.ezrachut},isStudent:${person.isStudent},`)
    }
    const validation = () => {
        let isFormValid = true;
        let err = {};
        if (person["age"] && (person["age"] > 90 || person["age"] < 50)) {
            isFormValid = false;
            err["age"] = { message: "גיל בין 50-90" };
        }
        if (!person["name"] || !person["name"].trim()) {
            isFormValid = false;
            err["name"] = { message: "שם שדה חובה" };
        }
        if (!person["password"] || !person["password"].trim()) {
            isFormValid = false;
            err["password"] = { message: "סיסמא שדה חובה" };
        }
        else if (person["password"].length < 4) {
            isFormValid = false;
            err["password"] = { message: "אורך הסיסמא לפחות 4 תווים" };
        }
        setErrors(err);
        return isFormValid;
    }
    return (<>
        <form className="form">
            <div>
                <label>שם</label>
                <input type="text" name="name" value={person.name} onChange={change} />
                {errors["name"] && <span>{errors["name"].message}</span>}
            </div>
            <div>
                <label>סיסמא</label>
                <input type="password" name="password" value={person.password} onChange={change} />
                {errors["password"] && <span>{errors["password"].message}</span>}

            </div>
            <div>
                <label>גיל</label>
                <input type="number" name="age" value={person.age} onChange={change} />
                {errors["age"] && <span>{errors["age"].message}</span>}
            </div>
            <div>
                <label>אמייל</label>
                <input type="email" name="email" value={person.email} onChange={change} />
            </div>
            <div>
                <label>סטודנט?</label>
                <input type="checkbox" name="isStudent" value={person.isStudent} onChange={change} />
            </div>
            <div className="rad">
                <label>:אזרחות</label>
                <br />
                <label>ישראלית</label>
                <input type="radio" name="ezrachut" value="israel" checked={person.ezrachut == 'israel'} onChange={change} />
                <br /> <label>אמריקאית</label>
                <input type="radio" name="ezrachut" value="usa" checked={person.ezrachut == 'usa'} onChange={change} />
                <br /> <label>אירופאית</label>
                <input type="radio" name="ezrachut" value="europe" checked={person.ezrachut == 'europe'} onChange={change} />
            </div>
            <input type="submit" className="addButton" value="הוסף" onClick={save} />
        </form>
    </>);
}

export default AddForm;