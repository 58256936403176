
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./App.css"
import SlideGallery from './SlideGallery';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import "./photo.css";

const RopePark = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 450, left: 0, behavior: 'smooth'});
  }, []);
  let nav = useNavigate();
  const goToBice = () => {
    nav("/bice");
  }
  const goToJip = () => {
    nav("/jip");
  }
  const goToRopePark = () => {
    nav("/ropePark");
  }
  const goToMeal = () => {
    nav("/MealsInTheField");
  }
  const goToLigth = () => {
    nav("/EventsAndProductions");
  }
  const goToGreen = () => {
    nav("/GreenAndSurprising");
  }
  const goToHome = () => {
    nav("/home");
  }
  const goToSnapling = () => {
    nav("/snapling");
  }
  const goToConnect = () => {
    nav("/connect2");
  }

  const images = [
    '../../pic/park/p (1).jpg',
    '../../pic/park/p (3).jpg',
    '../../pic/park/p (4).jpg',
    '../../pic/park/p (5).jpg',
    '../../pic/park/p (6).jpg',
    '../../pic/park/p (7).jpg',
    '../../pic/park/p (8).jpg',
    '../../pic/park/p (9).jpg',
    '../../pic/park/p (10).jpg',
    '../../pic/park/p (11).jpg',
    '../../pic/park/p (12).jpg',
    '../../pic/park/p (13).jpg',
    '../../pic/park/p (14).jpg',
    '../../pic/park/p (15).jpg',
    '../../pic/park/p (16).jpg',
    '../../pic/park/p (17).jpg',
    '../../pic/park/p (18).jpg',
    '../../pic/park/p (19).jpg',
    '../../pic/park/p (20).jpg',
    '../../pic/park/p (21).jpg',
    '../../pic/park/p (22).jpg',
    '../../pic/park/p (23).jpg',
    '../../pic/park/p (24).jpg',
    '../../pic/park/p (25).jpg',
    '../../pic/park/p (26).jpg',
    '../../pic/park/p (27).jpg',
    '../../pic/park/p (28).jpg',
    '../../pic/park/p (29).jpg',
    '../../pic/park/p (30).jpg',
    '../../pic/park/p (31).jpg',
    '../../pic/park/p (32).jpg',
    '../../pic/park/p (33).jpg',
    '../../pic/park/p (34).jpg',
    '../../pic/park/p (35).jpg',
    '../../pic/park/p (36).jpg',
    '../../pic/park/p (37).jpg',
    '../../pic/park/p (38).jpg',
    '../../pic/park/p (39).jpg'

  ];
  return (<>
  <br></br>
  <br></br>
  <div className='row'>

<div className='main'>

  
  {/* <div>
   

      <h1  style={{alignContent: "center", textAlign: 'center' }}>
      פארק חבלים אתגרי נייד 
      </h1>
      <img src="../../pic/park/p (10).jpg" alt="פארק חבלים" style={{ width: "700px", height: "500px", borderRadius: "5%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
     <Box sx={{ width: '90%', maxWidth: 600}} > 
      <Typography className='di' variant="h6" gutterBottom style={{alignContent: "center", textAlign: 'center' }}>
      <p>מכירים את הסיפור על העץ הנדיב? </p>
        <p>אנו מכירים הרבה עצים נדיבים עם שורשים עמוקים, גזעים רחבים וחזקים,</p>
        <p> ועלים רחבים, עצים שרק רוצים להעניק לכם פארק חבלים  -</p>
        <p> יום כיף, שעות הנאה מדהימות ואקסטרימיות של טיפוס, גלישה, והרבה צחוקים.</p>
        <p>נקים עבורכם מתקני אקסטרים - גשרים, אומגה, נדנדות עמודי טיפוס ועוד.</p>
        <p>נבחר מתקנים בהתאם לגיל המשתתפים ובהתאם ליער הנדיב שייבחר. </p>
        <p>פעילות שווה ומגוונת לקבוצות של ילדים חמודים ושובבים ולמשפחות.</p>
    <a href="tel:0548423630"><Button variant="contained" style={{ backgroundColor: 'red', display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו להזמין פארק חבלים  0548423630 </Button></a>

<br></br>
      </Typography>
    </Box>
    </div> */}
   
  <h1 style={{ alignContent: "center", textAlign: 'center' }}>
    פארק חבלים אתגרי נייד 
  </h1>
  <img src="../../pic/park/p (10).jpg" alt="פארק חבלים" style={{ width: "75%", height: "45%", borderRadius: "2%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
  <div>
  <p>מכירים את הסיפור על העץ הנדיב? </p>
        <p>אנו מכירים הרבה עצים נדיבים עם שורשים עמוקים, גזעים רחבים וחזקים,</p>
        <p> ועלים רחבים, עצים שרק רוצים להעניק לכם פארק חבלים  -</p>
        <p> יום כיף, שעות הנאה מדהימות ואקסטרימיות של טיפוס, גלישה, והרבה צחוקים.</p>
        <p>נקים עבורכם מתקני אקסטרים - גשרים, אומגה, נדנדות עמודי טיפוס ועוד.</p>
        <p>נבחר מתקנים בהתאם לגיל המשתתפים ובהתאם ליער הנדיב שייבחר. </p>
        <p>פעילות שווה ומגוונת לקבוצות של ילדים חמודים ושובבים ולמשפחות.</p>
    <a href="tel:0548423630"><Button variant="contained" style={{ backgroundColor: 'red', display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו להזמין פארק חבלים  0548423630 </Button></a>

  </div>
</div>
<div className='side'>
  <h3>אז במה עוד אנחנו מתמחים?</h3>
  <div onClick={goToLigth}>
    <h4> הפקות ואירועים</h4>
    <img className='demo' src="../../pic/ligth/l (4).jpg" alt="  הפקות ואירועים " style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
  <div onClick={goToMeal}>
    <h4> ארוחות בשטח </h4>
    <img className='demo' src="../../pic/meal/m (14).jpg" alt="אוכל" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
  <div onClick={goToBice}>
    <h4>  טיולי אופנים</h4>
    <img className='demo' src="../../pic/bice/b (14).jpg" alt="   טיולי אופנים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
  <div onClick={goToSnapling}>
    <h4> סנפלינג</h4>
    <img className='demo' src="../../pic/snap/sn (4).jpg" alt="  סנפלינג " style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
  </div>
</div>
</div>
<br></br>
<SlideGallery images={images} />
       

      
  </>);
};

export default RopePark;