
import React from 'react';
import { Zoom } from 'react-slideshow-image';
 import ImageSlider, { Slide } from "react-auto-image-slider";
//import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import "./SlideGallery.css";


const spanStyle = {
  padding: '10px',
  background: '#efefef',
  color: '#000000'
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '100%',
  width:'100%'
}



const SlideGallery = (props) => {
    return (<>
     
{/* <Slide>
            <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${props.images[0]})` }}>
                    
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${props.images[1]})` }}>
                    
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ backgroundImage: `url(${props.images[2]})` }}>
                   
                </div>
            </div>
        </Slide> */}

      <div className="slide-container" style={{alignItems: "center",justifyContent:"center",
      display: "block",marginLeft: "auto",marginRight: "auto",width: "80%"}}>
        <Zoom scale={1.4}>
          {
            props.images.map((each, index) => <img key={index} style={{width: "100%"}} src={each} />)
          }
        </Zoom> 
        {/* {/* <ImageSlider effectDelay={1500} autoPlayDelay={4000}>
        {props.images.map((each, index)=>(<Slide> <img key={index} style={{width: "50%"}} src={each} /> </Slide>))};
      
    </ImageSlider> */}
      </div>
      </>)
}
export default SlideGallery;
