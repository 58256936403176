import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import "./App.css";
import "./photo.css";
import SlideGallery from './SlideGallery';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect } from 'react';

const Jip = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 450, left: 0, behavior: 'smooth' });
  }, []);
  let nav = useNavigate();
  const goToBice = () => {
    nav("/bice");
  }
  const goToJip = () => {
    nav("/jip");
  }
  const goToRopePark = () => {
    nav("/ropePark");
  }
  const goToMeal = () => {
    nav("/MealsInTheField");
  }
  const goToLigth = () => {
    nav("/EventsAndProductions");
  }
  const goToGreen = () => {
    nav("/GreenAndSurprising");
  }
  const goToHome = () => {
    nav("/home");
  }
  const goToSnapling = () => {
    nav("/snapling");
  }
  const goToConnect = () => {
    nav("/connect2");
  }

  // let slideIndex = 1;

  // let showSlides=(n)=> {
  //   let i;
  //   let slides = document.getElementsByClassName("mySlides") ? document.getElementsByClassName("mySlides"):document.getElementsByClassName("mySlides");
  //   let dots = document.getElementsByClassName("demo");
  //   // let captionText = document.getElementById("caption");
  //   if (n > slides.length) {slideIndex = 1}
  //   if (n < 1) {slideIndex = slides.length}
  //   for (i = 0; i < slides.length; i++) {
  //     slides[i].style.display = "none";
  //   }
  //   for (i = 0; i < dots.length; i++) {
  //     dots[i].className = dots[i].className.replace(" active", "");
  //   }
  //   slides[slideIndex-1].style.display = "block";
  //   dots[slideIndex-1].className += " active";
  //   // captionText.innerHTML = dots[slideIndex-1].alt;
  // }

  // showSlides(slideIndex);

  // // Next/previous controls
  // function plusSlides(n) {
  //   showSlides(slideIndex += n);
  // }

  // // Thumbnail image controls
  // let currentSlide=(n)=> {
  //   showSlides(slideIndex = n);
  // }

  const images = [
    '../../pic/jip/j (1).jpg',
    '../../pic/jip/j (2).jpg',
    '../../pic/jip/j (3).jpg',
    '../../pic/jip/j (4).jpg',
    '../../pic/jip/j (5).jpg',
    '../../pic/jip/j (6).jpg',
    '../../pic/jip/j (7).jpg',
    '../../pic/jip/j (8).jpg',
    '../../pic/jip/j (9).jpg',
    '../../pic/jip/j (10).jpg',
    '../../pic/jip/j (11).jpg',
    '../../pic/jip/j (12).jpg',
    '../../pic/jip/j (13).jpg',
    '../../pic/jip/j (14).jpg',
    '../../pic/jip/j (15).jpg',
    '../../pic/jip/j (16).jpg',
    '../../pic/jip/j (17).jpg',
    '../../pic/jip/j (18).jpg',
    '../../pic/jip/j (19).jpg',
    '../../pic/jip/j (20).jpg',
    '../../pic/jip/j (21).jpg',
    '../../pic/jip/j (22).jpg',
    '../../pic/jip/j (23).jpg',
    '../../pic/jip/j (24).jpg',
    '../../pic/jip/j (25).jpg',
    '../../pic/jip/j (26).jpg',
    '../../pic/jip/j (27).jpg',
    '../../pic/jip/j (28).jpg',
    '../../pic/jip/j (29).jpg',
    '../../pic/jip/j (30).jpg',
    '../../pic/jip/j (31).jpg',
    '../../pic/jip/j (32).jpg',
    '../../pic/jip/j (33).jpg',
    '../../pic/jip/j (34).jpg',
    '../../pic/jip/j (35).jpg',
    '../../pic/jip/j (36).jpg',
    '../../pic/jip/j (37).jpg',
    '../../pic/jip/j (38).jpg',
    '../../pic/jip/j (39).jpg'
  ];

  return (<>

    <div className='row'>

      <div className='main'>
        <br></br>
        <h1 style={{ alignContent: "center", textAlign: 'center' }}>
          טיולי ג'יפים
        </h1>

        <img src="../../pic/jip/j (13).jpg" alt="ג'יפ" style={{ width: "75%", height: "45%", borderRadius: "2%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
        <div>

          <p> טיולי גיפים מלהיבים ומלאי אקסטרים בהרי יהודה, הרי ירושלים ומדבר יהודה. </p>
          <p>נרגיש מקרוב את ההרים שופעי הירוק נריח את הפריחה והלבלוב או נקשיב לשקט ונביט לאינסוף של המדבר העוצמתי. </p>
          <p>יצא לכם להיות פעם בטיול בוץ שווה?</p>
          <p>לבקר במאהל בדואי לחלוב עיזים ולראות מקרוב איך מכינים פיתות בדואיות ולבנה? </p>
          <p>יצא לכם לרדת לבורות מים קדומים? </p>
          <p>לרחוץ במעיינות עם מים צלולים וקרררים? </p>
          <p>יצא לכם לעשות טיול ג'יפים לאור ירח של אמצע חודש? </p>
          <p>פגשתם חיות מעניינות?</p>
          <p>כל טיול והחוויה שלו…</p>
          <p>בואו להנות בטיולים מתובלים בהסברים מרתקים,</p>
          <p>הג'יפאים שלנו הם אנשי שטח, חיים בשטח נושמים אותו ועתירי ניסיון בנסיעה בטוחה בשטח. </p>
          <p>לא תאמינו כמה מעניין יהיה לכם לפגוש אותם בטיול גיפים מרגש ועוצמתי. </p>
          <p>נתאים את הטיול לאופי הקבוצה ולאיזור המבוקש. תוך מומחיות בארגון ומתן שירות לקבוצות גדולות. </p>

          <a href="tel:0548423630"><Button variant="contained" style={{ backgroundColor: 'red', display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו להזמין טיול ג'יפים 0548423630 </Button></a>

        </div>
      </div>
      <div className='side'>
        <h3>אז במה עוד אנחנו מתמחים?</h3>
        <div onClick={goToSnapling}>
          <h4> סנפלינג</h4>
          <img className='demo' src="../../pic/snap/sn (4).jpg" alt="  סנפלינג " style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToRopePark}>
          <h4> פארק חבלים אתגרי נייד</h4>
          <img className='demo' src="../../pic/park/p (14).jpg" alt="פארק חבלים אתגרי נייד" style={{width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToBice}>
          <h4>  טיולי אופנים</h4>
          <img className='demo' src="../../pic/bice/b (14).jpg" alt="   טיולי אופנים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToMeal}>
          <h4> ארוחות בשטח </h4>
          <img className='demo' src="../../pic/meal/m (14).jpg" alt="אוכל" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
       
        
      </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <SlideGallery images={images} />



  </>);
};

export default Jip;