
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./App.css"
import SlideGallery from './SlideGallery';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import "./photo.css";

const MealsInTheField = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 450, left: 0, behavior: 'smooth' });
  }, []);
  let nav = useNavigate();
  const goToBice = () => {
    nav("/bice");
  }
  const goToJip = () => {
    nav("/jip");
  }
  const goToRopePark = () => {
    nav("/ropePark");
  }
  const goToMeal = () => {
    nav("/MealsInTheField");
  }
  const goToLigth = () => {
    nav("/EventsAndProductions");
  }
  const goToGreen = () => {
    nav("/GreenAndSurprising");
  }
  const goToHome = () => {
    nav("/home");
  }
  const goToSnapling = () => {
    nav("/snapling");
  }
  const goToConnect = () => {
    nav("/connect2");
  }

  const images = [
    '../../pic/meal/m (1).jpg',
    '../../pic/meal/m (2).jpg',
    '../../pic/meal/m (3).jpg',
    '../../pic/meal/m (4).jpg',
    '../../pic/meal/m (5).jpg',
    '../../pic/meal/m (6).jpg',
    '../../pic/meal/m (7).jpg',
    '../../pic/meal/m (8).jpg',
    '../../pic/meal/m (9).jpg',
    '../../pic/meal/m (10).jpg',
    '../../pic/meal/m (11).jpg',
    '../../pic/meal/m (12).jpg',
    '../../pic/meal/m (13).jpg',
    '../../pic/meal/m (14).jpg',
    '../../pic/meal/m (15).jpg',
    '../../pic/meal/m (16).jpg',
    '../../pic/meal/m (17).jpg',
    '../../pic/meal/m (18).jpg',
    '../../pic/meal/m (19).jpg',
    '../../pic/meal/m (20).jpg',
    '../../pic/meal/m (21).jpg',
    '../../pic/meal/m (22).jpg',
    '../../pic/meal/m (23).jpg',
    '../../pic/meal/m (24).jpg',
    '../../pic/meal/m (25).jpg',
    '../../pic/meal/m (26).jpg',
    '../../pic/meal/m (27).jpg',
    '../../pic/meal/m (28).jpg',
    '../../pic/meal/m (29).jpg',
    '../../pic/meal/m (30).jpg',
    '../../pic/meal/m (31).jpg',
    '../../pic/meal/m (32).jpg',
    '../../pic/meal/m (33).jpg',
    '../../pic/meal/m (34).jpg',
    '../../pic/meal/m (35).jpg',
    '../../pic/meal/m (36).jpg',
    '../../pic/meal/m (37).jpg',
    '../../pic/meal/m (38).jpg',
    '../../pic/meal/m (39).jpg',
    '../../pic/meal/m (40).jpg',
    '../../pic/meal/m (41).jpg',
    '../../pic/meal/m (42).jpg',
    '../../pic/meal/m (43).jpg',
    '../../pic/meal/m (44).jpg',
    '../../pic/meal/m (45).jpg',
    '../../pic/meal/m (46).jpg',
    '../../pic/meal/m (47).jpg',
    '../../pic/meal/m (48).jpg',
    '../../pic/meal/m (49).jpg',
    '../../pic/meal/m (50).jpg',
    '../../pic/meal/m (51).jpg',
    '../../pic/meal/m (52).jpg',
    '../../pic/meal/m (53).jpg',
    '../../pic/meal/m (54).jpg',
    '../../pic/meal/m (55).jpg',
    '../../pic/meal/m (56).jpg',
    '../../pic/meal/m (57).jpg',
    '../../pic/meal/m (58).jpg',
    '../../pic/meal/m (59).jpg',
    '../../pic/meal/m (60).jpg',
    '../../pic/meal/m (61).jpg',
    '../../pic/meal/m (62).jpg',
    '../../pic/meal/m (63).jpg',
    '../../pic/meal/m (64).jpg',
    '../../pic/meal/m (65).jpg'


  ];

  const images1 = [
    '../../pic/sweet/sw (1).jpg',
    '../../pic/sweet/sw (2).jpg',
    '../../pic/sweet/sw (3).jpg',
    '../../pic/sweet/sw (4).jpg',
    '../../pic/sweet/sw (5).jpg',
    '../../pic/sweet/sw (6).jpg',
    '../../pic/sweet/sw (7).jpg',
    '../../pic/sweet/sw (8).jpg',
    '../../pic/sweet/sw (9).jpg',
    '../../pic/sweet/sw (10).jpg',
    '../../pic/sweet/sw (11).jpg',
    '../../pic/sweet/sw (12).jpg',
    '../../pic/sweet/sw (13).jpg',
    '../../pic/sweet/sw (14).jpg',
    '../../pic/sweet/sw (15).jpg',
    '../../pic/sweet/sw (16).jpg',
    '../../pic/sweet/sw (17).jpg',
    '../../pic/sweet/sw (18).jpg',
    '../../pic/sweet/sw (19).jpg',
    '../../pic/sweet/sw (20).jpg'
  ];
  return (<>
    <div className='row'>

      <div className='main'>
        <br></br>
        <h1 style={{ alignContent: "center", textAlign: 'center' }}>
          ארוחות בשטח
        </h1>
        <img src="../../pic/meal/m (22).jpg" alt="ארוחות בשטח" style={{ width: "75%", height: "45%", borderRadius: "2%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
        <div>
          <p> בואו ליהנות מארוחה בשטח, ארוחה שהיא חוויה. </p>
          <p>קונספט - מקומות עוצמתיים וייחודיים בטבע מלאים בהשראה ואווירה. </p>
          <p>ויז'ואל - נוף פנורמי, יער קסום, מדבר עוצמתי תחת כיפת השמים.</p>
          <p>למי מתאים? לאירוע משפחתי, גיבוש לצוותים ולמגזר החינוכי  ולכל מי שאוהב אירוע מלא הוואי בראש שקט.</p>
          <p>ארוחות מפתיעות מגוונות עשירות טעימות בנוף מדהים,  עם המון השראה, ואווירה . </p>
          <p>מוגש ב'שולחנות בר' מזמינים או ליד שולחנות ערוכים.</p>
          <p>אנו נדאג לכל הפרטים הקטנים, אוכל איכותי, טרי ונקי, והעיקר בשפע,  כך שתרגישו טעים, נעים ומפנק.</p>
          <p>ליקטנו עבורכם טעימה ממגוון הארוחות השוות שלנו:</p>
          <ul>
            <li>חווית 'מנגל' עשירה, נתחים במעשנת, צ'יפס חם, אנטיפסטי מעולה,
              סלטים טובים ולא נשכח מתוק לקינוח</li>
            <li>כדורי פלאפל מושלמים הארוחה הישראלית והכיפית  הזאת</li>
            <li>אין כמו, אין כמו, אין כמו פיצה ! חמה ישר מהטאבון עם המון תוספות מפנקות </li>
            <li>בר מתוקים אגדי תמיד יתקבלו עם חיוך ענק על הפנים (מי לא אוהב שוקולד?)</li>
            <li>ארוחת פויקה מסביב למדורה, ספסלי זולה ומחצלות.</li>
          </ul>
          <p>עיצבנו לכם קשת רחבה של סגנונות וטעמים שיעשו לכם כיף.</p>

          <a href="tel:0548423630"><Button variant="contained" style={{ backgroundColor: 'red', display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו להזמין ארוחה בשטח 0548423630 </Button></a>

        </div>
      </div>
      <div className='side'>
        <h3>אז במה עוד אנחנו מתמחים?</h3>
        <div onClick={goToLigth}>
          <h4> הפקות ואירועים</h4>
          <img className='demo' src="../../pic/ligth/l (4).jpg" alt="  הפקות ואירועים " style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToBice}>
          <h4>  טיולי אופנים</h4>
          <img className='demo' src="../../pic/bice/b (14).jpg" alt="   טיולי אופנים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToSnapling}>
          <h4> סנפלינג</h4>
          <img className='demo' src="../../pic/snap/sn (4).jpg" alt="  סנפלינג " style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToJip}>
          <h4>טיולי ג'יפים</h4>
          <img className='demo' src="../../pic/jip/j (8).jpg" alt="ג'יפים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>

      </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <SlideGallery images={images} />



  </>);
};

export default MealsInTheField;
