
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./App.css"
import SlideGallery from './SlideGallery';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import "./photo.css";

const EventsAndProductions = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 450, left: 0, behavior: 'smooth' });
  }, []);
  let nav = useNavigate();
  const goToBice = () => {

    nav("/bice");
  }
  const goToJip = () => {
    nav("/jip");
  }
  const goToRopePark = () => {
    nav("/ropePark");
  }
  const goToMeal = () => {
    nav("/MealsInTheField");
  }
  const goToLigth = () => {
    nav("/EventsAndProductions");
  }
  const goToGreen = () => {
    nav("/GreenAndSurprising");
  }
  const goToHome = () => {
    nav("/home");
  }
  const goToSnapling = () => {
    nav("/snapling");
  }
  const goToConnect = () => {
    nav("/connect2");
  }

  const images = [
    '../../pic/ligth/l (1).jpg',
    '../../pic/ligth/l (2).jpg',
    '../../pic/ligth/l (3).jpg',
    '../../pic/ligth/l (4).jpg',
    '../../pic/ligth/l (5).jpg',
    '../../pic/ligth/l (6).jpg',
    '../../pic/ligth/l (7).jpg',
    '../../pic/ligth/l (8).jpg',
    '../../pic/ligth/l (9).jpg',
    '../../pic/ligth/l (10).jpg',
    '../../pic/ligth/l (11).jpg',
    '../../pic/ligth/l (12).jpg',
    '../../pic/ligth/l (13).jpg',
    '../../pic/ligth/l (14).jpg',
    '../../pic/ligth/l (15).jpg',
    '../../pic/ligth/l (16).jpg',
    '../../pic/ligth/l (17).jpg',
    '../../pic/ligth/l (18).jpg',
    '../../pic/ligth/l (19).jpg',
    '../../pic/ligth/l (20).jpg',
    '../../pic/ligth/l (21).jpg',
    '../../pic/ligth/l (22).jpg',
    '../../pic/ligth/l (23).jpg',
    '../../pic/ligth/l (24).jpg',
    '../../pic/ligth/l (25).jpg',
    '../../pic/ligth/l (26).jpg',
    '../../pic/ligth/l (27).jpg',
    '../../pic/ligth/l (28).jpg',
    '../../pic/ligth/l (29).jpg',
    '../../pic/ligth/l (30).jpg',
    '../../pic/ligth/l (31).jpg',
    '../../pic/ligth/l (32).jpg',
    '../../pic/ligth/l (33).jpg',
    '../../pic/ligth/l (34).jpg',
    '../../pic/ligth/l (35).jpg',
    '../../pic/ligth/l (36).jpg',
    '../../pic/ligth/l (37).jpg',
    '../../pic/ligth/l (38).jpg',
    '../../pic/ligth/l (39).jpg',
    '../../pic/ligth/l (40).jpg',
    '../../pic/ligth/l (41).jpg',
    '../../pic/ligth/l (42).jpg',
    '../../pic/ligth/l (43).jpg',
    '../../pic/ligth/l (44).jpg',
    '../../pic/ligth/l (45).jpg',

  ];

  return (<>
    <div className='row'>

      <div className='main'>
        <br></br>
        <h1 style={{ alignContent: "center", textAlign: 'center' }}>
          אירועים והפקות
        </h1>
        <img src="../../pic/ligth/l (31).jpg" alt="אירועים והפקות" style={{ width: "75%", height: "45%", borderRadius:"2%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
        <div>
          <p>אירועי שיא - עוצמה של קול, אור, תמונה וצבע.</p>
          <p> אווירה מחשמלת וחוויה חושית שיעשו לכם את האירוע ללא פחות מ-וואוווו!</p>
          <p>מזמינים עכשיו את האירוע הבא שלכם -
            אירוע פנים או אירוע חוץ</p>
          <p>הגברה, תאורה, מסכי לד, במות, שרותי חשמל ולוגיסטיקה </p>
          <p>לחברה צוות מקצועי ומיומן.
            וניתן דגש על ציוד מקצועי חדיש ומתקדם, והכל - להצלחת האירוע שלכם. </p>
          <b>כי חשוב לנו שהאירוע יצליח…! </b>
          <br></br>
          <br></br>
          <br></br>
          <a href="tel:0548423630"><Button variant="contained" style={{ backgroundColor: 'red', display: "block", marginLeft: "auto", marginRight: "auto", fontSize: "30px" }}>התקשרו עכשיו להזמנת אירוע מנצח   0548423630 </Button></a>

        </div>
      </div>
      <div className='side'>
        <h3>אז במה עוד אנחנו מתמחים?</h3>
        <div onClick={goToGreen}>
          <h4> מחיצה מצמחיה</h4>
          <img className='demo' src="../../pic/me/me (4).jpg" alt="  מחיצה מצמחיה " style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToMeal}>
          <h4> ארוחות בשטח </h4>
          <img className='demo' src="../../pic/meal/m (14).jpg" alt="אוכל" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        <div onClick={goToJip}>
          <h4>טיולי ג'יפים</h4>
          <img className='demo' src="../../pic/jip/j (8).jpg" alt="ג'יפים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
        
        <div onClick={goToBice}>
          <h4>  טיולי אופנים</h4>
          <img className='demo' src="../../pic/bice/b (14).jpg" alt="   טיולי אופנים" style={{ width: "300px", height: "200px", borderRadius: "5%" }} />
        </div>
      </div>
    </div>
    <br></br>
    <SlideGallery images={images} />



  </>)
};

export default EventsAndProductions;